import { Icon } from '@buggy/shared'
import { Form, Input, InputProps } from 'antd'
import { Rule } from 'antd/es/form'
import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import styled, { useTheme } from 'styled-components'
import { FieldProps } from '@/components/form/fields/types'

const ICON_SIZE = 20

type PasswordFieldProps = FieldProps<InputProps>

const PasswordField: FunctionComponent<PasswordFieldProps> = ({
  formItemProps,
  elementProps,
}) => {
  const { formatMessage } = useIntl()
  const theme = useTheme()

  const rules: Rule[] = [
    {
      required: true,
      message: formatMessage({ id: 'error:psw_empty' }),
    },
    {
      min: 6,
      message: formatMessage({ id: 'error:psw_fail' }),
    },
    {
      max: 20,
      message: formatMessage({ id: 'error:psw_fail' }),
    },
    ...(formItemProps?.rules ? formItemProps.rules : []),
  ]

  return (
    <Form.Item
      name='password'
      rules={rules}
      validateTrigger='onBlur'
      {...formItemProps}
    >
      <StyledInput.Password
        placeholder={formatMessage({
          id: 'account:forget_input_psw_placeholder',
        })}
        prefix={
          <Icon
            color={theme.colors.brand.primary}
            fontSize={ICON_SIZE}
            name='key'
          />
        }
        {...elementProps}
      />
    </Form.Item>
  )
}

const StyledInput = styled(Input)`
  .ant-input-prefix {
    margin-inline-end: 8px;
  }
`

export default PasswordField
