import { useCallback } from 'react'
import { UserType } from '@/hooks/use-authorization/constants'
import { InstagramLoginCallbackReturnValues } from '@/hooks/use-instagram-login-callback-effect'
import { useSocialLogin } from '@/hooks/use-social-login'
import { SupportedLanguages } from '@/i18n/config'
import { InstagramUser } from '@/types/api/instagram'
import { SocialLoginResponse } from '@/types/schema/login-schema'

export type InstagramSocialLoginPayload = {
  oAuthType: 'instagram'
  oAuthUserId: InstagramUser['id']
  token: InstagramLoginCallbackReturnValues['token']
  trial_code?: string
}

export const useInstagramSocialLogin = (): ((
  socialData: InstagramSocialLoginPayload,
  {
    language,
    type,
    trial_code,
  }: { language: SupportedLanguages; type?: UserType; trial_code?: string },
) => Promise<SocialLoginResponse>) => {
  const socialLogin = useSocialLogin()

  return useCallback(
    async (
      socialData: InstagramSocialLoginPayload,
      {
        language,
        type,
        trial_code,
      }: { language: SupportedLanguages; type: UserType; trial_code?: string },
    ): Promise<SocialLoginResponse> => {
      const response = await socialLogin({
        ...socialData,
        language,
        type,
        trial_code,
        oAuthUserId: `${socialData.oAuthUserId}`,
      })

      return response
    },
    [socialLogin],
  )
}
