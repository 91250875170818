import { useEffect, useRef } from 'react'
import { useLazyFetchInstagramUserQuery } from '@/api/instagram-api'
import {
  INSTAGRAM_AUTH_CHANNEL_NAME,
  InstagramTokenMessageData,
} from '@/constants/instagram'
import { InstagramUser } from '@/types/api/instagram'

export type InstagramLoginCallbackReturnValues = InstagramUser & {
  token: string
}

export type InstagramLoginCallbackPayload = {
  onAuthorizationSuccess: (data: InstagramLoginCallbackReturnValues) => void
  onAuthorizationFail: (error: unknown) => void
}

export const useInstagramLoginCallbackEffect = ({
  onAuthorizationSuccess,
  onAuthorizationFail,
}: InstagramLoginCallbackPayload): void => {
  const [getInstagramUser] = useLazyFetchInstagramUserQuery()

  const onAuthorizationSuccessRef = useRef(onAuthorizationSuccess)
  useEffect(() => {
    onAuthorizationSuccessRef.current = onAuthorizationSuccess
  })

  const onAuthorizationFailRef = useRef(onAuthorizationFail)
  useEffect(() => {
    onAuthorizationFailRef.current = onAuthorizationFail
  })

  useEffect(() => {
    const channel = new BroadcastChannel(INSTAGRAM_AUTH_CHANNEL_NAME)
    channel.onmessage = async (
      event: MessageEvent<InstagramTokenMessageData>,
    ): Promise<void> => {
      const data = event.data

      const token = data.token
      if (!token) {
        onAuthorizationFailRef.current(new Error('Instagram authorize failed'))

        return
      }

      try {
        const user = await getInstagramUser({ token }).unwrap()

        onAuthorizationSuccessRef.current({ ...user, token })
      } catch (error) {
        onAuthorizationFailRef.current(error)
      }
    }

    return (): void => {
      channel.close()
    }
  }, [getInstagramUser])
}
